/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        $('.equal-height').matchHeight({ byRow: false });

        $('.equal-height-row').matchHeight({ byRow: true });

        $('.box-clic').click(function () {
          var urlPage = $(this).find('a').attr('href');
          window.location = urlPage;
        });

        $('#search-form-desktop').submit(function (e) {

          if ($('#search-form-desktop input[type=text]').val().length == 0) {
            // $(this).submit();
            e.preventDefault();
          } else {

          }
        });

        $(document).click(function (e) {
          if (!$(e.target).is('.menu-collapse, .menu-item, .menu')) {
            $('.menu-collapse').collapse('hide');
            //console.log(e.target);
          }
        });

        $('.open-menu').click(function () {

          var idDiv = $(this).attr('data-iddiv');
          $('.collapse').collapse('hide');
          $('#' + idDiv).collapse('show');
          $('.open-menu').removeClass('active');
          $('#' + idDiv + '-btn').addClass('active');
        });

        $('.chiffre-counter').appear(function () {
          var id = $(this).attr('id');
          var suffixe = $(this).attr('data-suffixe');
          var chiffre = $(this).attr('data-counter');
          var prefixe = $(this).attr('data-prefixe');
          var compteur = new CountUp(id, 0, chiffre, 0, 2.5, { separator: ' ', suffix: suffixe, prefix: prefixe });
          compteur.start();
        });

        $('.js-scrollTo').on('click', function () { //smooth scroll pour les liens dans la page
          var page = $(this).attr('href');
          var speed = 750;
          //$('html, body').animate( { scrollTop: $(page).offset().top - $('header > .navbar').height() }, speed );
          $('html, body').animate({ scrollTop: $(page).offset().top }, speed);
          return false;
        });

        if (window.location.hash) { //smooth scroll pour les liens externes (au chargement de la page)
          //			console.log('test'+window.location.hash);
          //			console.log($(window.location.hash).offset().top);

          if ($(window.location.hash).length) {
            $('html, body').animate({
              //scrollTop: $(window.location.hash).offset().top - $('header > .navbar').height()
              scrollTop: $(window.location.hash).offset().top
            }, 750);
          }
        }

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.owl-logo').owlCarousel({
          nav: true,
          loop: true,
          margin: 70,
          autoWidth: true,
          items: 6,
          autoplay: true,
          smartSpeed: 1000,
          autoplayTimeout: 2000,
          navText: ['<i class=" fa fa-chevron-circle-right"></i>', '<i class=" fa fa-chevron-circle-left"></i>'],

          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 3
            },
            1000: {
              items: 5
            }
          }
        });

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        // carte = new Maplace({

        //   map_options: {
        //     scrollwheel: false,
        //     zoomControl: true,
        //     mapTypeControl: false,
        //     scaleControl: false,
        //     streetViewControl: false,
        //     rotateControl: false,
        //     fullscreenControl: false,
        //   },

        //   styles: {
        //     'Greyscale': [{
        //       featureType: 'all',
        //       stylers: [
        //         { saturation: -100 },
        //         { gamma: 0.50 }
        //       ]
        //     }]
        //   },

        //   /*stroke_options: {
        //    strokeColor: '#19becf',
        //    strokeOpacity: 0.8,
        //    strokeWeight: 5,
        //    fillColor: '#19becf',
        //    fillOpacity: 0.4
        //    },*/

        //   //locations: adresses,

        //   //type: 'polyline', //pour le tracé du parcours

        //   //start: 1,
        //   controls_on_map: false
        // });

        // jQuery.post(
        //   // see tip #1 for how we declare global javascript variables
        //   MyAjax.ajaxurl,
        //   {
        //     // here we declare the parameters to send along with the request
        //     // this means the following action hooks will be fired:
        //     // wp_ajax_nopriv_myajax-submit and wp_ajax_myajax-submit
        //     action: 'myajax-submit',
        //     dataType: 'json',

        //     // other parameters can be added along with "action"
        //     //assuranceID : idAssurance
        //   },
        //   function (response) {
        //     //console.log( response );

        //     carte.Load({ locations: response });
        //   }
        // );

      }
    },
    // About us page, note the change from about-us to about_us.
    'post_type_archive_ressource': {
      finalize: function () {

        $.fn.select2.amd.define('select2/i18n/fr', [], function () {
          return {
            noResults: function () {
              return 'Sélectionnez une thématique.';
            },
          };
        });

        $('.select-thematique').select2({
          //placeholder: "Thématique",
          //allowClear: true,
          minimumResultsForSearch: Infinity,
        });

        //chargement des étapes en fonction de la thématique choisie
        $('.select-thematique').on('change', function () {
          var slugThematique = $('.select-thematique option:selected').val();

          jQuery.post(
            // see tip #1 for how we declare global javascript variables
            MyAjax.ajaxurl,
            {
              action: 'getEtapesByThematique',
              dataType: 'json',
              slugThematique: slugThematique,
            },
            function (response) {
              if (response == 0) {
                sel.data('select2').dataAdapter.updateOptions([]);
              } else {
                sel.data('select2').dataAdapter.updateOptions(response);
              }

            }
          );

        });

        if ($('.select-thematique').val() != '') {
          var slugThematique = $('.select-thematique option:selected').val();
          jQuery.post(
            // see tip #1 for how we declare global javascript variables
            MyAjax.ajaxurl,
            {
              action: 'getEtapesByThematique',
              dataType: 'json',
              slugThematique: slugThematique,
            },
            function (response) {
              sel.data('select2').dataAdapter.updateOptions(response);
              if (typeof idEtape !== 'undefined') {
                $('.select-etape').val(idEtape).trigger('change.select2');
              }
            }
          );
        }

        $('.select-resource').select2({
          //placeholder: "Type de ressource",
          allowClear: false,
          minimumResultsForSearch: Infinity,
        });

        $.fn.select2.amd.define('select2/data/customAdapter',
          ['select2/data/array', 'select2/utils'],
          function (ArrayAdapter, Utils) {
            function CustomDataAdapter($element, options) {
              CustomDataAdapter.__super__.constructor.call(this, $element, options);
            }

            Utils.Extend(CustomDataAdapter, ArrayAdapter);
            CustomDataAdapter.prototype.updateOptions = function (data) {
              this.$element.find('option').remove(); // remove all options
              this.addOptions(this.convertToOptions(data));
            };
            return CustomDataAdapter;
          }
        );

        var customAdapter = $.fn.select2.amd.require('select2/data/customAdapter');

        var sel = $('.select-etape').select2({
          placeholder: 'Etape',
          //allowClear: true,
          dataAdapter: customAdapter,
          minimumResultsForSearch: Infinity,
          //data: pills
        });

      }
    },

    'single_etape': {
      finalize: function () {
        carte = new Maplace({

          map_options: {
            scrollwheel: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          },

          styles: {
            'Greyscale': [{
              featureType: 'all',
              stylers: [
                { saturation: -100 },
                { gamma: 0.50 }
              ]
            }]
          },

          //locations: adresses,

          //start: 1,
          controls_on_map: false
        });

        if (typeof (etapeLocation) !== 'undefined') {
          carte.Load({ locations: etapeLocation });
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
